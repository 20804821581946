import HTTP from "@/axios";

export default {
	getRegistrationDetails(registrationToken) {
		return new Promise(((resolve, reject) => {
			HTTP.get(`registration/${registrationToken}`).then((response) => {
				if (response)
					return resolve(response.data);

				return resolve();
			}).catch((error) => {
				reject(error);
			});
		}));
	},

	setupPassword(data) {
		return new Promise(((resolve, reject) => {
			HTTP.post("registration/setup-password", data).then((response) => {
				if (response)
					return resolve(response.data);

				return resolve();
			}).catch((error) => {
				reject(error);
			});
		}));
	}
};
