<template>
  <div class="layout-login">
    <div class="card-login">
      <LogoWhite />

      <div class="login-signin" v-if="!finished">
        <div>
          <div class="login-header" v-if="userData">
            <Heading5>Bienvenue {{ userData.firstName }} !</Heading5>
            <Subtitle1 color="gray-2" v-if="isResetPassword">
              Choisissez votre nouveau mot de passe
            </Subtitle1>
            <Subtitle1 color="gray-2" v-else>
              Afin de finaliser votre inscription, veuillez choisir un mot de
              passe.
            </Subtitle1>
          </div>

          <!-- IF ERROR MESSAGE -->
          <p v-if="this.errorMessage" style="color:red;text-align:center;padding-top:15px;">{{ this.errorMessage }}</p>
        </div>
        <form @submit.prevent="handleSubmit" v-if="userData">
          <div class="container-field">
            <Subtitle1 color="blue-1">Mot de passe</Subtitle1>
            <input
              type="password"
              placeholder="Mot de passe"
              name="password"
              autocomplete="off"
              v-model="auth.password"
            />

            <span
              ><Tooltip>
                <template #title>
                  <Subtitle2 color="white">Critères de mot de passe</Subtitle2>
                  <div>
                    <Caption color="white"
                      >Votre mot de passe doit contenir :
                      <ul style="padding-left: 20px; margin-right: 20px">
                        <li>Au moins 8 caractères</li>
                        <li>Un caractère spécial : @, &, ‘, (, §, !, ), -.</li>
                        <li>Au moins une majuscule</li>
                        <li>Au moins un chiffre</li>
                      </ul>
                    </Caption>
                  </div>
                </template>
                <span class="helper"
                  >Quels critères pour le mot de passe ?</span
                >
              </Tooltip></span
            >
          </div>

          <div class="container-field">
            <Subtitle1 color="blue-1">Confirmation de mot de passe</Subtitle1>
            <input
              type="password"
              placeholder="Confirmation de mot de passe"
              name="passwordConfirm"
              v-model="auth.passwordConfirm"
            />
          </div>

          <div style="margin-top: 16px; justify-content: center; display: flex">
            <Button
              @click="setupPassword()"
              theme="primary"
              size="large"
              :shadow="true"
              >ACCÉDER À KERMONY</Button
            >
          </div>
        </form>
        <div class="no-account">
          <Subtitle1 color="gray-2">Besoin d’aide ? </Subtitle1>

          <a class="link" href="javascript:;">Contactez nous!</a>
        </div>
      </div>
      <!-- IF FINISHED -->
      <div class="login-signin" v-if="finished">
        <div class="login-header">
          <Heading5 v-if="userData"
            >Votre compte est désormais validé !</Heading5
          >
          <Subtitle1 color="gray-2">
            Vous allez être redirigé dans quelques secondes...
          </Subtitle1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/login.min.css";

import registerApi from "@/api/register";
import LogoWhite from "@/views/Auth/Login/LogoWhite";
import Heading5 from "@/components/Texts/Heading5";
import Subtitle1 from "@/components/Texts/Subtitle1";
import Button from "@/components/Buttons/Button";
import Subtitle2 from "@/components/Texts/Subtitle2";
import Caption from "@/components/Texts/Caption";
import Tooltip from "@/components/Tooltips/Tooltip";

export default {
  name: "Register",
  components: {
    Tooltip,
    Caption,
    Subtitle2,
    Button,
    Subtitle1,
    Heading5,
    LogoWhite,
  },
  data() {
    return {
      auth: {},
      errorMessage: null,
      // User data
      userData: null,
      registrationToken: null,
      // Account verified
      finished: false,
      isResetPassword: false,
    };
  },

  mounted() {
    this.isResetPassword = !!this.$route.query.resetPasswd;
    this.registrationToken = this.$route.params.registrationToken;

    if (!this.registrationToken) return this.$router.push("/");

    registerApi
      .getRegistrationDetails(this.registrationToken)
      .then((response) => {
        this.userData = response;
      })
      .catch((error) => {
        if (error.response) {
          console.log(
            "[getRegistrationDetails]",
            "Error from API:",
            error.response.data.message
          );
          this.errorMessage = error.response.data.message;
        }
      });
  },

  methods: {
    setupPassword() {
      if (!this.auth.password || !this.auth.passwordConfirm)
        return alert("Veuillez rentrer un mot de passe.");

      if (this.auth.password && this.auth.password.length < 8)
        return alert("Votre mot de passe doit faire au moins 8 caractères.");

      const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$");

      if (!passwordRegex.test(this.auth.password))
        return alert("Votre mot de passe ne respecte pas les exigences de sécurité");

      if (this.auth.password !== this.auth.passwordConfirm)
        return alert("Les mots de passe doivent être indentiques");

      // Reset errors
      this.errorMessage = null;

      const data = {
        registrationToken: this.registrationToken,
        password: this.auth.password,
      };

      registerApi
        .setupPassword(data)
        .then((response) => {
          this.finished = true;
          // Redirect to login with autofilled email
          setTimeout(() => this.$router.push({ path: '/login', query: { email: response.email }}), 2000);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "[setupPassword]",
              "Error from API:",
              error.response.data.message
            );
            this.errorMessage = error.response.data.message;
          }
        });
    },
  },
};
</script>

<style scoped>
.layout-login {
  height: 100%;
  width: 100%;
  background: url(/filigrane.png) #fbf9fa;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-login .card-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  gap: 24px;

  position: absolute;
  width: 472px;
  /*height: 500px;*/
  /*min-height: 400px;*/

  /* Kermony/Blanc */

  background: #ffffff;
  /* Card shadow */

  box-shadow: 0px 2px 12px rgba(33, 36, 78, 0.1);
  border-radius: 5px;
  max-width: calc(100% - 32px);
  margin: 16px;
}

.layout-login .login-header {
  display: flex;
  gap: 8px;
  flex-direction: column;
  text-align: center;
}

.layout-login .login-signin,
.layout-login .login-forgot,
.layout-login .login-forgot-success {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.layout-login form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.layout-login .no-account {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.container-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.container-field input {
  background: #ffffff;
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  padding: 8px 0px 8px 8px;
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #21244e;
}

.container-field input:focus {
  outline: none;
}

.container-field .helper {
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 280;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.4px;
  text-decoration-line: underline;

  color: var(--kermony-gris-2);
  cursor: pointer;
}

.layout-login .link {
  color: var(--kermony-office-rose);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.container-pin-code {
  display: flex;
  gap: 8px;
  position: relative;
  justify-content: center;
}

.container-pin-code input {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  color: transparent;
}

.container-pin-code input:focus {
  outline: none;
}

.container-pin-code input::-webkit-outer-spin-button,
.container-pin-code input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.container-pin-code input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.container-pin-code .digit {
  width: 60px;
  height: 100px;
  background: rgba(207, 210, 212, 0.3);
  border: 1px solid #cfd2d4;
  border-radius: 5px;
  color: var(--kermony-office-bleu-1);
  font-family: "Weissenhof Grotesk";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
